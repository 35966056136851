<template>
<div>
  <b-container fluid>
    <b-row class="py-4 mb-5 shadow">
      <b-col>
        <h1 style="font-size: 24px; font-weight: bold;">Export raw data</h1>
        <p>CSV raw data. Useful if you are building your own visualisations or analytics in spreadsheets or other databases.</p>
        <div>
          <b-btn class="ml-3 mr-3" @click="excelMetrics">Metrics</b-btn>
          <b-btn class="ml-3 mr-3" @click="excelFrameworksMetrics">Metrics - Frameworks</b-btn>
          <b-btn class="ml-3 mr-3" @click="excelMetricsTaxonomy">Metrics - Taxonomy</b-btn>
          <b-btn class="ml-3 mr-3" @click="excelDataelements">Data elements</b-btn>
          <b-btn class="ml-3 mr-3" @click="excelDataelementsMetrics">Data elements - Metrics</b-btn>
          <b-btn class="ml-3 mr-3" @click="excelFrameworks">Frameworks</b-btn>

        </div>
      </b-col>
    </b-row>
    <b-row class="py-4 my-5 shadow">
      <b-col>
        <h1 style="font-size: 24px; font-weight: bold;">Export report format</h1>
        <p>Exporting in Word format with templated output coming soon - work in progress...</p>
      </b-col>
    </b-row>
  </b-container>
</div>
</template>

<script>
import moment from 'moment'
import zipcelx from 'zipcelx'

export default {
  name: 'MetricsDownload',
  components: {
  },
  computed: {
    dataelements: function () {
      return this.$store.state.dataelements
    },
    dataelements_metrics: function () {
      const resuts = []
      this.dataelements.forEach(dataelement => {
        dataelement.metrics.forEach(metric => {
          resuts.push({
            dataelement_id: dataelement.id,
            dataelement_name: dataelement.name,
            dataelement_definition: dataelement.definition,
            metric_id: metric.id,
            metric_name: metric.name
          })
        })
      })
      return resuts
    },
    frameworks: function () {
      return this.$store.state.reportingframeworks
    },
    frameworks_metrics: function () {
      const results = []
      this.metrics.forEach(metric => {
        metric.reportingframeworks.forEach(framework => {
          results.push({
            metric_id: metric.id,
            metric_name: metric.name,
            framework_id: framework.id,
            framework_name: framework.name,
            framework_reference: framework.reference
          })
        })
      })
      return results
    },
    metrics: function () {
      return this.$store.state.metrics
    },
    metrics_taxonomy: function () {
      const results = []
      this.metrics.forEach(metric => {
        metric.taxonomy.forEach(taxonomy => {
          taxonomy.parents.forEach(parent => {
            results.push({
              metric_id: metric.id,
              metric_name: metric.name,
              category_id: taxonomy.id,
              category_name: taxonomy.name,
              pillar_id: parent.id,
              pillar_name: parent.name
            })
          })
        })
      })
      return results
    },
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  data () {
    return {
    }
  },
  created: function () {
    document.title = "ESG Metrics"
    console.log(this.metrics)
  },
  methods: {
    excelDataelements: function () {
      this.$stat.log({ page: 'metrics download', action: 'download data elements Excel' })
      const data = []
      const fields = [
        { field: 'id', name: 'id' },
        { field: 'name', name: 'name' },
        { field: 'definition', name: 'definition' }
      ]
      const headings = fields.map(field => {
        return { value: field.name, type: 'string' }
      })
      data.push(headings)
      this.dataelements.forEach(r => {
        const row = fields.map(field => {
          return { value: r[field.field], type: 'string' }
        })
        data.push(row)
      })
      const config = { filename: 'ESG_Metrics_DataElements_' + moment().toISOString(), sheet: { data: data } }
      zipcelx(config)
    },
    excelDataelementsMetrics: function () {
      this.$stat.log({ page: 'metrics download', action: 'download data elements metrics Excel' })
      const data = []
      const fields = [
        { field: 'dataelement_id', name: 'data element id' },
        { field: 'dataelement_name', name: 'data element name' },
        { field: 'dataelement_definition', name: 'data element definition' },
        { field: 'metric_id', name: 'metric id' },
        { field: 'metric_name', name: 'metric name' }
      ]
      const headings = fields.map(field => {
        return { value: field.name, type: 'string' }
      })
      data.push(headings)
      this.dataelements_metrics.forEach(r => {
        const row = fields.map(field => {
          return { value: r[field.field], type: 'string' }
        })
        data.push(row)
      })
      const config = { filename: 'ESG_Metrics_DataElements_Metrics_' + moment().toISOString(), sheet: { data: data } }
      zipcelx(config)
    },
    excelFrameworks: function () {
      this.$stat.log({ page: 'metrics download', action: 'download reporting frameworks Excel' })
      const data = []
      const fields = [
        { field: 'id', name: 'id' },
        { field: 'name', name: 'name' }
      ]
      const headings = fields.map(field => {
        return { value: field.name, type: 'string' }
      })
      data.push(headings)
      this.frameworks.forEach(r => {
        const row = fields.map(field => {
          return { value: r[field.field], type: 'string' }
        })
        data.push(row)
      })
      const config = { filename: 'ESG_Metrics_ReportingFrameworks_' + moment().toISOString(), sheet: { data: data } }
      zipcelx(config)
    },
    excelFrameworksMetrics: function () {
      this.$stat.log({ page: 'metrics download', action: 'download reporting frameworks metrics Excel' })
      const data = []
      const fields = [
        { field: 'metric_id', name: 'metric id' },
        { field: 'metric_name', name: 'metric name' },
        { field: 'framework_id', name: 'framework id' },
        { field: 'framework_name', name: 'framework name' },
        { field: 'framework_reference', name: 'framework reference' }
      ]
      const headings = fields.map(field => {
        return { value: field.name, type: 'string' }
      })
      data.push(headings)
      this.frameworks_metrics.forEach(r => {
        const row = fields.map(field => {
          return { value: r[field.field], type: 'string' }
        })
        data.push(row)
      })
      const config = { filename: 'ESG_Metrics_Frameworks_Metrics_' + moment().toISOString(), sheet: { data: data } }
      zipcelx(config)
    },
    excelMetrics: function () {
      this.$stat.log({ page: 'metrics download', action: 'download metrics Excel' })
      const data = []
      const fields = [
        { field: 'id', name: 'id' },
        { field: 'name', name: 'name' }
      ]
      const headings = fields.map(field => {
        return { value: field.name, type: 'string' }
      })
      data.push(headings)
      this.metrics.forEach(metric => {
        const row = fields.map(field => {
          return { value: metric[field.field], type: 'string' }
        })
        data.push(row)
      })
      const config = { filename: 'ESG_Metrics_' + moment().toISOString(), sheet: { data: data } }
      zipcelx(config)
    },
    excelMetricsTaxonomy: function () {
      this.$stat.log({ page: 'metrics download', action: 'download metrics taxonomy Excel' })
      const data = []
      const fields = [
        { field: 'metric_id', name: 'metric id' },
        { field: 'metric_name', name: 'metric name' },
        { field: 'category_id', name: 'category id' },
        { field: 'category_name', name: 'category name' },
        { field: 'pillar_id', name: 'pillar id' },
        { field: 'pillar_name', name: 'pillar name' }
      ]
      const headings = fields.map(field => {
        return { value: field.name, type: 'string' }
      })
      data.push(headings)
      this.metrics_taxonomy.forEach(r => {
        const row = fields.map(field => {
          return { value: r[field.field], type: 'string' }
        })
        data.push(row)
      })
      const config = { filename: 'ESG_Metrics_Taxonomy_' + moment().toISOString(), sheet: { data: data } }
      zipcelx(config)
    }
  }
}
</script>

<style>
</style>
